import { CSSObject } from "@emotion/core";
import { variables } from "../../styles/variables";

interface CookiePreferncesStyles {
  list: CSSObject;
  listItem: CSSObject;
  cookieType: CSSObject;
  description: CSSObject;
}

export const styles: CookiePreferncesStyles = {
  list: {
    listStyleType: "none",
    paddingLeft: 0,
    paddingRight: variables.spacing.medium
  },
  listItem:  {
    marginBottom: variables.spacing.large
  },
  cookieType: {
    display: "inline-block",
    marginLeft: variables.spacing.small,
    marginBottom: variables.spacing.small,
    fontWeight: "bold"
  },
  description: {
    display: "block"
  }
};
