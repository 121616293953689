import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList, {
  GraphQLError
} from "../components/graphql-error-list";
import SEO from "../components/SEO";
import Layout from "../components/Layout";

import Page from "../components/Page";
import CookiePreferences from "../components/CookiePreferences";
import { SiteInterface } from ".";

export const query = graphql`
  query PrivacySettingsQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
  }
`;

interface PrivacySettingsPageProps {
  data: { site: SiteInterface };
  errors: Array<GraphQLError>;
}

const PrivacySettingsPage: React.FC<PrivacySettingsPageProps> = ({
  data,
  errors
}) => {
  const { site } = data;
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  return (
    <>
      {site && (
        <SEO
          title={`Cookie Preferences | ${site.title}`}
          description={`Set Cookie prefernces. ${site.description}`}
          keywords={site.keywords}
        />
      )}
      <Page title="Cookie Preferences" id="customCookiePreferencesPage">
        <CookiePreferences />
      </Page>
    </>
  );
};

export default PrivacySettingsPage;
