import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import { useCookies } from "react-cookie";
import { useLocation } from "@reach/router";
import { Checkbox } from "react-input-checkbox";
import { stringToBoolean } from "../../helpers/stringToBoolean";
import { styles } from "./styles";
import useIsMounted from "ismounted";

const CookiePreferences: React.FC<{}> = () => {
  const location = useLocation();
  const mounted = useIsMounted();
  const [cookies, setCookies] = useCookies();
  const [checkboxOptions, setCheckboxOptions] = useState({
    functional: stringToBoolean(cookies.wmdjFc),
    performance: stringToBoolean(cookies["wmdjPc"])
  });

  useEffect(() => {
    if (!checkboxOptions.functional && mounted) {
      window.localStorage.clear();
    }
  }, [checkboxOptions.functional]);

  const handleFunctionalCookies = () => {
    if (mounted) {
      const currentStatus = stringToBoolean(cookies.wmdjFc);
      setCheckboxOptions({ ...checkboxOptions, functional: !currentStatus });
      setCookies("wmdjFc", !currentStatus, {
        expires: new Date("Thu, 31 Dec 2099 23:59:59 UTC")
      });
    }
  };

  const handlePerfomanceCookies = () => {
    if (mounted) {
      const currentStatus = stringToBoolean(cookies.wmdjPc);
      setCheckboxOptions({ ...checkboxOptions, performance: !currentStatus });
      setCookies("wmdjPc", !currentStatus, {
        expires: new Date("Thu, 31 Dec 2099 23:59:59 UTC")
      });
      setCookies(
        `ga-disable-${process.env.GATSBY_GA_TRACKING_ID}`,
        !currentStatus,
        {
          expires: new Date("Thu, 31 Dec 2099 23:59:59 UTC"),
          path: "/"
        }
      );
      if (!currentStatus) {
        initializeAndTrack(location);
      }
    }
  };

  useEffect(() => {
    const { functional, performance } = checkboxOptions;
    if (mounted) {
      setCookies("CookieConsent", functional && performance);
    }
  }, [checkboxOptions]);

  return (
    <div style={{ flexDirection: "column-reverse" }}>
      <p>
        Some essential features of this website will not work without cookies
        and localStorage. Also, having some other cookies switched off can
        affect the way you can access the podcast or the website. Please check
        your cookie settings below and turn on any cookies you're happy with.
        "Strictly necessary" cookies can't be turned off. But Functional and
        Performance cookies can be turned on or off below. You can learn more
        about our use of cookies{" "}
        <Link to="/our-use-of-cookies-local-storage-and-other-technologies">
          here
        </Link>
        .
      </p>
      <ul css={styles.list}>
        <li css={styles.listItem}>
          <Checkbox disabled={true} value={true} onChange={() => null}>
            <span css={styles.cookieType}>Strictly necessary cookies</span>
            <span css={styles.description}>
              These cookies are essential so that you can move around the
              website and use its features.
            </span>
          </Checkbox>
        </li>
        <li css={styles.listItem}>
          {" "}
          <Checkbox
            value={checkboxOptions.functional}
            onChange={handleFunctionalCookies}
          >
            <span css={styles.cookieType}>
              Functional Cookies/Local storage
            </span>
            <span css={styles.description}>
              These cookies allow the website to remember choices you make. This
              includes things like remembering the last episode you played or
              the place you last paused an episode.
            </span>
          </Checkbox>
        </li>
        <li css={styles.listItem}>
          <Checkbox
            value={checkboxOptions.performance}
            onChange={handlePerfomanceCookies}
          >
            <span css={styles.cookieType}>Performance Cookies</span>
            {" - "}
            <span css={styles.description}>
              These cookies help to improve the performance of the website by
              helping us understand how you use the site.
            </span>
          </Checkbox>
        </li>
      </ul>
    </div>
  );
};

export default CookiePreferences;
